@tailwind base;
@tailwind components;
@tailwind utilities;

/* Typography
------------------------------------------------------------------------- */

a {
	color: inherit;
}

a:hover {
	text-decoration: none;
}

img {
	width: 100%;
}

/* Portal z-indexing
------------------------------------------------------------------------- */
#root {
	position: relative;
	z-index: 1;
	height: 100vh;
}
